import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LightingGalleryContent from "../components/GalleryContent/LightingGalleryContent"

function LightingGallery() {
  return (
    <div>
      <Layout>
        <SEO title="Lighting Gallery" />
        <LightingGalleryContent />
        <Link to="/">Go back to the homepage</Link>
      </Layout>
    </div>
  )
}

export default LightingGallery
